define("discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/composer-fields/topic-custom-field-composer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.reply}}
  <TopicCustomFieldInput
    @fieldValue={{this.fieldValue}}
    @onChangeField={{this.onChangeField}}
  />
  {{/unless}}
  */
  {
    "id": "a48J/QBe",
    "block": "[[[41,[51,[30,0,[\"reply\"]]],[[[8,[39,1],null,[[\"@fieldValue\",\"@onChangeField\"],[[30,0,[\"fieldValue\"]],[30,0,[\"onChangeField\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"topic-custom-field-input\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/composer-fields/topic-custom-field-composer.hbs",
    "isStrictMode": false
  });
});