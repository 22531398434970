define("discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/topic-title/topic-custom-field-topic-title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.topic.editingTopic}}
  {{#if this.fieldUrl}} 
    <a href="{{this.fieldUrl}}" target="_blank" class="referenced-button">
      <svg xmlns="http://www.w3.org/2000/svg" id="svg5" 
      viewBox="0 0 346.76 286.46" class="referenced-icon"
      
      >
    <defs>
      <style>
        .cls-1 {
          stroke: #606f7e;
        }
  
        .cls-1, .cls-2 {
          stroke-width: 22.68px;
        }
  
        .cls-1, .cls-2, .cls-3 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
  
        .cls-2 {
          stroke: #000;
        }
  
        .cls-3 {
          stroke: #764d82;
          stroke-width: 22.68px;
        }
      </style>
    </defs>
    <g id="layer1">
      <path id="path6303" class="cls-2" d="M335.42,86.9L173.38,11.34,11.34,86.9l162.04,75.56,162.04-75.56Z"/>
      <path id="path6311" class="cls-1" d="M11.34,124.45l162.04,75.56,162.04-75.56"/>
      <path id="path6313" class="cls-3" d="M11.34,162.01l162.04,75.56,162.04-75.56"/>
      <path id="path6315" class="cls-2" d="M11.34,199.56l162.04,75.56,162.04-75.56"/>
    </g>
  </svg>
      <span>View Process</span>
    </a>
  {{/if}}
  {{/unless}}
  */
  {
    "id": "vfYZaNJz",
    "block": "[[[41,[51,[30,0,[\"topic\",\"editingTopic\"]]],[[[41,[30,0,[\"fieldUrl\"]],[[[1,\"  \"],[10,3],[15,6,[29,[[30,0,[\"fieldUrl\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"referenced-button\"],[12],[1,\"\\n    \"],[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,1,\"svg5\"],[14,\"viewBox\",\"0 0 346.76 286.46\"],[14,0,\"referenced-icon\"],[12],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"style\"],[12],[1,\"\\n      .cls-1 {\\n        stroke: #606f7e;\\n      }\\n\\n      .cls-1, .cls-2 {\\n        stroke-width: 22.68px;\\n      }\\n\\n      .cls-1, .cls-2, .cls-3 {\\n        fill: none;\\n        stroke-linecap: round;\\n        stroke-linejoin: round;\\n      }\\n\\n      .cls-2 {\\n        stroke: #000;\\n      }\\n\\n      .cls-3 {\\n        stroke: #764d82;\\n        stroke-width: 22.68px;\\n      }\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"g\"],[14,1,\"layer1\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,1,\"path6303\"],[14,0,\"cls-2\"],[14,\"d\",\"M335.42,86.9L173.38,11.34,11.34,86.9l162.04,75.56,162.04-75.56Z\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,1,\"path6311\"],[14,0,\"cls-1\"],[14,\"d\",\"M11.34,124.45l162.04,75.56,162.04-75.56\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,1,\"path6313\"],[14,0,\"cls-3\"],[14,\"d\",\"M11.34,162.01l162.04,75.56,162.04-75.56\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,1,\"path6315\"],[14,0,\"cls-2\"],[14,\"d\",\"M11.34,199.56l162.04,75.56,162.04-75.56\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n    \"],[10,1],[12],[1,\"View Process\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"unless\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/topic-title/topic-custom-field-topic-title.hbs",
    "isStrictMode": false
  });
});