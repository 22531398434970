define("discourse/plugins/discourse-topic-custom-fields/discourse/connectors/topic-title/topic-custom-field-topic-title", ["exports", "@glimmer/component", "@ember/controller", "@ember/object/computed", "@ember/service"], function (_exports, _component, _controller, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * type:        step
   * number:      8
   * title:       Display your field value in the topic
   * description: Display the value of your custom topic field below the
   *              title in the topic
   *              list.
   */

  class TopicCustomFieldTopicTitle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topic", [_controller.inject]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.alias)("siteSettings.topic_custom_field_name")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    get fieldValue() {
      return this.args.outletArgs.model.get(this.fieldName);
    }
    isValidProcessId(str) {
      // UUID format validation regex
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/?$/i;
      return uuidRegex.test(str);
    }
    get fieldUrl() {
      console.log(this.fieldValue);
      if (!this.fieldValue) return null;

      // If it's already a valid process ID, use it directly
      if (this.isValidProcessId(this.fieldValue)) {
        return `https://www.fabublox.com/process-editor/${this.fieldValue}`;
      }

      // If it's a full URL, extract and validate the process ID
      const parts = this.fieldValue.split("/");
      const lastPart = parts[parts.length - 1];
      if (this.isValidProcessId(lastPart)) {
        return `https://www.fabublox.com/process-editor/${lastPart}`;
      }

      // Invalid input - return null or handle as needed
      return null;
    }
  }
  _exports.default = TopicCustomFieldTopicTitle;
});